import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import Subscribe from "../components/Subscribe/Subscribe"
import { get } from "../../src/helpers/utils"
import ContentfulComponents from "../helpers/ContentfulComponents"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head";

const NewsletterPage = props => {
    const { pageContext } = props
    const { seoData, globalComponents, componentProps, pageType, hero, dataLayer } = pageContext || {}
    const subscribeProps = globalComponents?.find(component => component?.SubscribeForm)
    const subscribeCollection = get(subscribeProps, "SubscribeForm.subscribeFormCollection.items[0]", {})

    // GTM custom dataLayer Object based on environment
    if (typeof window !== "undefined") {
        const dataLayerObj = {
            ...dataLayer,
            page: {
                url: window.location.href,
                title: seoData?.title || seoData?.metaTitle,
            },
            content: {
                type: "Newsletter Page",
                category: "",
            },
        }
        window.gtmDataLayer = dataLayerObj
    }

    return (
        <Layout seoData={seoData} globalComponents={globalComponents} hero={hero}>
            <ContentfulComponents componentsProps={componentProps} pageType={pageType} />

            <div className="lg:px[10px] mx-auto mt-[30px] mb-[70px] w-[586px] max-w-[100%] px-[30px]">
                <Subscribe showThanksButton={false} {...subscribeCollection} />
            </div>
        </Layout>
    )
}

export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            {process.env.GATSBY_ACTIVE_ENV === 'production' && <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{__html: gTagScriptConsent}}
            />}
        </>
    )
}

NewsletterPage.propTypes = {
    pageContext: PropTypes.shape({
        contentfulData: PropTypes.object,
        dataLayer: PropTypes.object,
    }),
}

export default NewsletterPage
